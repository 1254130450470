import { ValidLocale } from "i18n";
import { generators } from "../shared/generators/generators";
import { siteConfiguration } from "../../components/shared/sites/sites";

interface Props {
  style: string[];
  sortBy?: string;
  locale: any;
  pathCode: ValidLocale;
  queryParam: any;
}

export const CategoryText = ({
  locale,
  sortBy = "hot",
  style = ["all"],
  pathCode,
  queryParam,
}: Props) => {
  // Helper function to generate style-related text
  const getStyleLabel = (styleArray: any, queryParam: any) => {
    if (queryParam) {
      let SEOText;
      switch (siteConfiguration.category) {
        case "Porn":
          SEOText = locale.SEOTitle;
          break;
        case "Hentai":
          SEOText = locale.SEOTitleh;
          break;
        case "Furry":
          SEOText = locale.SEOTitlef;
          break;
        case "GayPorn":
          SEOText = locale.SEOTitleg;
          break;
        case "AsianPorn":
          SEOText = locale.SEOTitlea;
          break;
        case "ShemalePorn":
          SEOText = locale.SEOTitles;
          break;
        default:
          SEOText = locale.SEOTitle; // Default case if none of the categories match
      }
      return SEOText;
    } else if (styleArray[0] === "all") {
      // Use the specific locale entry for 'all'
      return `${locale.aiPornH1}${locale.allH1}${locale.styleH1Plural}`;
    } else if (styleArray.length > 1) {
      return `${locale.aiPornH1}${styleArray
        .map((styleId: string) => {
          const genre = generators.find((gen) => gen.id === styleId);
          const labelObj = genre?.styleName.find((langObj) => langObj.name === locale.key);
          return labelObj ? labelObj.value : "No Label";
        })
        .join(", ")}`;
    } else {
      const genre = generators.find((gen) => gen.id === styleArray[0]);
      const labelObj = genre?.styleName.find((langObj) => langObj.name === locale.key);
      return `${locale.aiPornH1}${labelObj ? labelObj.value : "No Label"}`;
    }
  };

  function capitalizeWords(str: any): any {
    return str.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const buildText = () => {
    // Generating the heading based on sortBy
    switch (sortBy) {
      case "hot": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${locale.hotH1
          } ${queryParam ? `${capitalizeWords(queryParam)} ` : ""}${getStyleLabel(style, queryParam)}${(style.length > 1 && !queryParam)
            ? locale.styleH1Plural
            : (style[0] === "all" || queryParam) ? "" : locale.styleH1Singular
          }`;
      case "new": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${locale.newH1
          } ${queryParam ? `${capitalizeWords(queryParam)} ` : ""}${getStyleLabel(style, queryParam)}${(style.length > 1 && !queryParam)
            ? locale.styleH1Plural
            : (style[0] === "all" || queryParam) ? "" : locale.styleH1Singular
          }`;
      case "top": //${queryParam ? `${locale.searchResults} ` : ""}
        return `${locale.topH1
          } ${queryParam ? `${capitalizeWords(queryParam)} ` : ""}${getStyleLabel(style, queryParam)}${(style.length > 1 && !queryParam)
            ? locale.styleH1Plural
            : (style[0] === "all" || queryParam) ? "" : locale.styleH1Singular
          }`;
      default:
        return null;
    }
  };

  return (
    <h1 className="mb-4 text-lg font-bold text-white sm:text-xl">
      {buildText()}
    </h1>
  );
};
