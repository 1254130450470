import React from "react";
import Masonry from "react-masonry-css";

interface MysnoryLayoutProps {
  children: React.ReactNode;
  state: any;
}

const MysnoryLayout: React.FC<MysnoryLayoutProps> = ({ children, state }) => {

  const breakpointColumnsObj = {
    default: state.viewPorts.default,
    1024: state.viewPorts.lg,
    768: state.viewPorts.md,
    640: state.viewPorts.sm, //prev 640
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className={`flex w-full h-full`}
    >
      {React.Children.map(children, child => (
        <div className={`${(state.expandContent) ? 'pl-0 pb-0' : 'pl-0 pb-0 sm:pb-[14px] sm:px-[7px]'}`}>
          {child}
        </div>
      ))}
    </Masonry>
  );
};

export default MysnoryLayout;
