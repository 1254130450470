"use client"
import React from "react";
import PictureCard from "./pictureCard";
import MysnoryLayout from "./mysnoryLayout";
import { useDisplayModeContext } from "../../../app/context/displayModeStore";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface CardWrapperProps {
  data: Post[];
  locale: any;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ data, locale }) => {
  const { state } = useDisplayModeContext();

  return (
    <MysnoryLayout state={state}>
      {data.map((card: Post, i: number) => (
        <PictureCard key={i} firstImg={i} data={card} locale={locale} state={state} />
      ))}
    </MysnoryLayout>
  );
};

export default CardWrapper;
