"use client";
import React, { useState, useEffect } from "react";
import { likePost, unlikePost } from "../../../lib/api/post";
import { useGlobalContext } from "../../../app/context/store";
import Link from "next/link";

const LikeButton = ({
  initialLikes,
  id,
  locale,
  userLiked,
  newURL,
}: {
  initialLikes: number;
  id: string;
  locale: any;
  userLiked: boolean;
  newURL: string;
}) => {
  const [likes, setLikes] = useState(initialLikes);
  const [liked, setLiked] = useState(false);
  const { user } = useGlobalContext();

  function formatViews(number: number) {
    if (number < 1000) {
      return number.toString(); // for numbers less than 1000, just convert to string
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k'; // for thousands
    } else {
      return (number / 1000000).toFixed(1) + 'm'; // for millions
    }
  }

  const handleClick = () => {
    if (user._id === "none") {
      // setShowSignInModal(true);
      // window.alert(locale.accessViaAccount);
      return;
    }

    if (liked) {
      unlikePost(id);
    } else {
      likePost(id);
    }

    setLiked((prevLiked) => !prevLiked);
    setLikes((prevLikes) => (!liked ? prevLikes + 1 : prevLikes - 1));
  };

  //use effect setLiked(userLiked)
  useEffect(() => {
    setLiked(userLiked);
    setLikes(initialLikes);
  }, [userLiked, initialLikes]);

  return (
    <>
      {user._id === "none" ? (
        <Link
          href={newURL || "/"}
          title="Like AI Porn"
          className={`${liked ? "bg-[#DD0772]" : "bg-[#2B2D31]/20 backdrop-blur-xl"
            } w-full mb-1.5 mr-2 flex flex-col items-center justify-center rounded-lg`}
        >
          <div className="mx-1 text-lg text-white">{liked ? "♥" : "♡"}</div>
          <div className="text-xs text-white mx-auto mb-0.5">{formatViews(likes)}</div>
        </Link>
      ) : (
        <button
          onClick={handleClick}
          title="Like AI Porn"
          className={`${liked ? "bg-[#DD0772]" : "bg-[#2B2D31]/20 backdrop-blur-xl"
            } w-full mb-1.5 mr-2 flex flex-col items-center justify-center rounded-lg`}
        >
          <div className="mx-1 text-lg text-white">{liked ? "♥" : "♡"}</div>
          <div className="text-xs text-white mx-auto mb-0.5">{formatViews(likes)}</div>
        </button>
      )
      }
    </>
  );
};

export default LikeButton;
